import React from 'react';
import { graphql } from 'gatsby';
import Layout from '/src/components/layout';
import Head from '/src/components/head';
import { H1, H2 } from '/src/components/wrapper/h';

export default ({ data, location }) => {
	const siteUrl = data.site.siteMetadata.url;
	return (
		<>
			<Head>
				<title>たらこ</title>
				<meta name='description' content='たらこはローソンお試し引換券の情報を発信します。' />
				<link rel='canonical' href={siteUrl + location.pathname}></link>
				<meta name='keywords' content='ローソン,お試し引換券,ポイパ,ポイ活' />
			</Head>

			<Layout>
				<H1 id='purpose'>ローソンお試し引換券によるポイ活を支援</H1>
				<p>
					<span className='siteName'>たらこ</span>
					はお得度が分かるように、お試し引換券の商品に割引率を加えて表示します。
				</p>
				<br />

				<section>
					<p>お試し引換券の配布開始リマインダーを提供します。</p>
					<p>
						A. Twitterで配布開始をツイートします。
						<br />
						次アカウントをフォローし、通知を有効(鈴マークをオン)にしてください。
						<br />
						Twitterアカウント：<a href='https://twitter.com/OtokuTarako'>@OtokuTarako</a>
					</p>
					<br />
					<p>
						B. カレンダーアプリに配布開始スケジュールを表示できます。
						<br />
						B1. Googleカレンダーに表示するには次をクリック。
						<br />
						<a href='https://calendar.google.com/calendar/u/0?cid=M25tOXZwOHV2Y3ViYzEzZnVybGI4NnN1ZzRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ'>
							ローソン お試し引換券 Googleカレンダー
						</a>
						<br />
						B2. iCalendar対応アプリに表示するには、アプリに次のicsファイルURLを指定。
						<br />
						https://calendar.google.com/calendar/ical/3nm9vp8uvcubc13furlb86sug4%40group.calendar.google.com/public/basic.ics
					</p>
				</section>

				<hr />
				<H2 id='list'>お試し引換券の実施一覧</H2>

				<details open>
					<summary>2024年</summary>
					<ul>
						<li>
							<a href='/otamesi/202401.html'>1月</a>
						</li>
						<li>
							<a href='/otamesi/202402.html'>2月</a>
						</li>
						<li>
							<a href='/otamesi/202403.html'>3月</a>
						</li>
						<li>
							<a href='/otamesi/202404.html'>4月</a>
						</li>
						<li>
							<a href='/otamesi/202405.html'>5月</a>
						</li>
						<li>
							<a href='/otamesi/202406.html'>6月</a>
						</li>
						<li>
							<a href='/otamesi/202407.html'>7月</a>
						</li>
						<li>
							<a href='/otamesi/202408.html'>8月</a>
						</li>
						<li>
							<a href='/otamesi/202409.html'>9月</a>
						</li>
						<li>
							<a href='/otamesi/202410.html'>10月</a>
						</li>
						<li>
							<a href='/otamesi/202411.html'>11月</a>
							<span style={{ color: 'black', backgroundColor: 'yellow', fontStyle: 'italic' }}>New</span>
						</li>
					</ul>
				</details>

				<details>
					<summary>2023年</summary>
					<ul>
						<li>
							<a href='/otamesi/202301.html'>1月</a>
						</li>
						<li>
							<a href='/otamesi/202301maturi.html'>1月祭</a>
						</li>
						<li>
							<a href='/otamesi/202302.html'>2月</a>
						</li>
						<li>
							<a href='/otamesi/202303.html'>3月</a>
						</li>
						<li>
							<a href='/otamesi/202304.html'>4月</a>
						</li>
						<li>
							<a href='/otamesi/202304maturi.html'>4月祭</a>
						</li>
						<li>
							<a href='/otamesi/202305.html'>5月</a>
						</li>
						<li>
							<a href='/otamesi/202306.html'>6月</a>
						</li>
						<li>
							<a href='/otamesi/202306maturi.html'>6月祭</a>
						</li>
						<li>
							<a href='/otamesi/202307.html'>7月</a>
						</li>
						<li>
							<a href='/otamesi/202308.html'>8月</a>
						</li>
						<li>
							<a href='/otamesi/202308maturi.html'>8月祭</a>
						</li>
						<li>
							<a href='/otamesi/202309.html'>9月</a>
						</li>
						<li>
							<a href='/otamesi/202310.html'>10月</a>
						</li>
						<li>
							<a href='/otamesi/202310maturi.html'>10月祭</a>
						</li>
						<li>
							<a href='/otamesi/202311.html'>11月</a>
						</li>
						<li>
							<a href='/otamesi/202312.html'>12月</a>
						</li>
						<li>
							<a href='/otamesi/202312maturi.html'>12月祭</a>
						</li>
					</ul>
				</details>

				<details>
					<summary>2022年</summary>
					<ul>
						<li>
							<a href='/otamesi/202201.html'>1月</a>
						</li>
						<li>
							<a href='/otamesi/202201maturi.html'>1月祭</a>
						</li>
						<li>
							<a href='/otamesi/202202.html'>2月</a>
						</li>
						<li>
							<a href='/otamesi/202203.html'>3月</a>
						</li>
						<li>
							<a href='/otamesi/202204.html'>4月</a>
						</li>
						<li>
							<a href='/otamesi/202204maturi.html'>4月祭</a>
						</li>
						<li>
							<a href='/otamesi/202205.html'>5月</a>
						</li>
						<li>
							<a href='/otamesi/202206.html'>6月</a>
						</li>
						<li>
							<a href='/otamesi/202206maturi.html'>6月祭</a>
						</li>
						<li>
							<a href='/otamesi/202207.html'>7月</a>
						</li>
						<li>
							<a href='/otamesi/202208.html'>8月</a>
						</li>
						<li>
							<a href='/otamesi/202208maturi.html'>8月祭</a>
						</li>
						<li>
							<a href='/otamesi/202209.html'>9月</a>
						</li>
						<li>
							<a href='/otamesi/202210.html'>10月</a>
						</li>
						<li>
							<a href='/otamesi/202210maturi.html'>10月祭</a>
						</li>
						<li>
							<a href='/otamesi/202211.html'>11月</a>
						</li>
						<li>
							<a href='/otamesi/202212.html'>12月</a>
						</li>
					</ul>
				</details>

				<details>
					<summary>2021年</summary>
					<ul>
						<li>
							<a href='/otamesi/202101.html'>1月</a>
						</li>
						<li>
							<a href='/otamesi/202101maturi.html'>1月祭</a>
						</li>
						<li>
							<a href='/otamesi/202102.html'>2月</a>
						</li>
						<li>
							<a href='/otamesi/202103.html'>3月</a>
						</li>
						<li>
							<a href='/otamesi/202104.html'>4月</a>
						</li>
						<li>
							<a href='/otamesi/202104maturi.html'>4月祭</a>
						</li>
						<li>
							<a href='/otamesi/202105.html'>5月</a>
						</li>
						<li>
							<a href='/otamesi/202106.html'>6月</a>
						</li>
						<li>
							<a href='/otamesi/202106maturi.html'>6月祭</a>
						</li>
						<li>
							<a href='/otamesi/202107.html'>7月</a>
						</li>
						<li>
							<a href='/otamesi/202108.html'>8月</a>
						</li>
						<li>
							<a href='/otamesi/202108maturi.html'>8月祭</a>
						</li>
						<li>
							<a href='/otamesi/202109.html'>9月</a>
						</li>
						<li>
							<a href='/otamesi/202110.html'>10月</a>
						</li>
						<li>
							<a href='/otamesi/202110maturi.html'>10月祭</a>
						</li>
						<li>
							<a href='/otamesi/202111.html'>11月</a>
						</li>
						<li>
							<a href='/otamesi/202112.html'>12月</a>
						</li>
					</ul>
				</details>
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				url
			}
		}
	}
`;
